document.addEventListener('DOMContentLoaded', (event) => {
  if (document.getElementById('attachmentInput')) {
    const applicationAttachment = document.getElementById('attachmentInput');

    applicationAttachment.addEventListener('change', e => {
      const attachmentInput = document.querySelector('input[type="file"]');
      const attachmentName = attachmentInput.files[0].name;
      document.getElementById('applicationAttachment').innerHTML = attachmentName;
    });
  };
});