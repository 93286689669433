import '../css/main.scss';
// Elements
import './elements/swipers';
import './elements/faq';
import './elements/nav';
import './elements/search';
// Pages

// Vendor
import 'lazysizes';
import './vendor/swiper';
import './elements/tocbot';
import './elements/toTop';
import './elements/burger-menu';
import './elements/display-cv';
import './elements/cursor';
import AOS from 'aos';

AOS.init();

// Lander scripts
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'
import '../js/newswiper.js';
import '../js/sliders-cursors.js';
import '../js/blog-cursor.js';

Alpine.plugin(collapse)
window.Alpine = Alpine;
Alpine.start();