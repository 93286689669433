// ** FADE OUT FUNCTION **
function fadeOut(el) {
  el.style.opacity = 1;
  (function fade() {
    if ((el.style.opacity -= 0.1) < 0) {
      el.style.display = 'none';
    } else {
      requestAnimationFrame(fade);
    }
  }());
}

// ** FADE IN FUNCTION **
function fadeIn(el, display) {
  el.style.opacity = 0;
  el.style.display = display || 'block';
  (function fade() {
    let val = parseFloat(el.style.opacity);
    if (!((val += 0.1) > 1)) {
      el.style.opacity = val;
      requestAnimationFrame(fade);
    }
  }());
}

document.addEventListener('DOMContentLoaded', (event) => {
  const servicesLink = document.querySelector('.services-link');
  const nav = document.querySelector('.header-nav');
  const whiteServicesLink = document.querySelector('.white-services-link');
  const whiteNav = document.querySelector('.white-header-nav');
  const fullPage = document.querySelector('body');
  const links = document.querySelectorAll('.no-dropdown > a');

  servicesLink.addEventListener('mouseenter', () => {
    if (nav.style.opacity <= 0){
      fadeIn(nav);
    }
  });
  whiteServicesLink.addEventListener('mouseenter', () => {
    console.log(1)
    if (whiteNav.style.opacity <= 0){
      fadeIn(whiteNav);
    }
  });

  fullPage.addEventListener('click', () => {
    fadeOut(nav);
    fadeOut(whiteNav);
  });

  links.forEach((e) => {
    e.addEventListener('mouseenter', () => {
      fadeOut(nav);
      fadeOut(whiteNav);
    });
  });
});
