document.addEventListener( 'DOMContentLoaded', function () {

    let cursor = document.querySelector('.blog-cursor');
    let cursorInner = cursor.querySelector('.blog-cursor-inner');
    let blogContainer = document.querySelector('.blog-cursor-container');
    if(cursor){
        document.addEventListener('mousemove',function(d){
            let x = d.clientX; 
            let y = d.clientY;
            cursor.style.left = `${x}px`;
            cursor.style.top = `${y}px`;
        });
    }
        blogContainer.addEventListener('mouseenter',function(e){
            let x = e.clientX; 
            let y = e.clientY;
            cursor.style.left = `${x}px`;
            cursor.style.top = `${y}px`;
            cursorInner.classList.add('blog-cursor-active');

        });
        blogContainer.addEventListener('mouseleave',function(e){
            cursorInner.classList.remove('blog-cursor-active');
        });
    });
