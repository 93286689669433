document.addEventListener( 'DOMContentLoaded', function () {

    let cursor = document.querySelector('.cursor');
    let cursorInner = cursor.querySelector('.cursor-inner');
    let slider = document.querySelectorAll('.cursor-container');
    if(cursor){
        document.addEventListener('mousemove',function(d){
            let x = d.clientX; 
            let y = d.clientY;
            cursor.style.left = `${x}px`;
            cursor.style.top = `${y}px`;
        });

        slider.forEach(function(d){
            d.addEventListener('mousemove',function(e){
                if(this.querySelector('.slider-wrapper').classList.contains('arrow-right')){
                    cursor.classList.add('next-container');
                    cursor.classList.remove('prev-container');
                    cursor.classList.remove('drag-container');
                }else if (this.querySelector('.slider-wrapper').classList.contains('arrow-left')){
                    cursor.classList.add('prev-container');
                    cursor.classList.remove('next-container');
                    cursor.classList.remove('drag-container');
                }else if (this.querySelector('.slider-wrapper').classList.contains('drag-text')){
                    cursor.classList.remove('prev-container');
                    cursor.classList.remove('next-container');
                    cursor.classList.add('drag-container');
                }
            })
            d.addEventListener('mouseenter',function(e){
                let x = e.clientX; 
                let y = e.clientY;
                cursor.style.left = `${x}px`;
                cursor.style.top = `${y}px`;
                cursorInner.classList.add('cursor-active');
                if(this.querySelector('.slider-wrapper').classList.contains('cursor-white')){
                    cursorInner.classList.add('cursor-inner-white');
                    cursorInner.classList.remove('cursor-inner-dark');
                }else if(this.querySelector('.slider-wrapper').classList.contains('cursor-dark')){
                    cursorInner.classList.remove('cursor-inner-white');
                    cursorInner.classList.add('cursor-inner-dark');
                }
            });
            d.addEventListener('mousedown',function(e){
                cursorInner.classList.add('cursor-down');
            });
            d.addEventListener('mouseup',function(e){
                cursorInner.classList.remove('cursor-down');
            });
            d.addEventListener('mouseleave',function(){
                if(d.closest("section").querySelector('.marquee')){
                }
                cursorInner.classList.remove('cursor-active');
                
            })
        })
    }
});