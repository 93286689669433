document.addEventListener('DOMContentLoaded', (event) => {
  if (document.getElementsByClassName('faq').length) {
    const faqs = document.querySelectorAll('.faq');

    function collapseSection(element) {
      const sectionHeight = element.scrollHeight;
      const elementTransition = element.style.transition;
      element.style.transition = '';
      requestAnimationFrame(() => {
        element.style.height = `${sectionHeight}px`;
        element.style.transition = elementTransition;
        requestAnimationFrame(() => {
          element.style.height = `${0}px`;
        });
      });
      element.setAttribute('data-collapsed', 'true');
    }

    function expandSection(element) {
      const sectionHeight = element.scrollHeight;
      element.style.height = `${sectionHeight}px`;
      element.addEventListener('transitionend', function (e) {
        element.removeEventListener('transitionend', arguments.callee);
        element.style.height = null;
      });
      element.setAttribute('data-collapsed', 'false');
    }

    for (const faq of faqs) {
      faq.addEventListener('click', (e) => {
        const section = faq.querySelector('.faq-a');
        const openIcon = faq.querySelector('.fa-plus');
        const closeIcon = faq.querySelector('.fa-minus');
        const isCollapsed = section.getAttribute('data-collapsed') === 'true';

        if (isCollapsed) {
          expandSection(section);
          section.setAttribute('data-collapsed', 'false');
          openIcon.style.opacity = '0';
          openIcon.style.display = 'none';
          closeIcon.style.opacity = '1';
          closeIcon.style.display = 'block';
        } else {
          collapseSection(section);
          closeIcon.style.opacity = '0';
          closeIcon.style.display = 'none';
          openIcon.style.opacity = '1';
          openIcon.style.display = 'block';
        }
      });
    }
  }
});