import {swiperHover} from './cursor.js';

document.addEventListener('DOMContentLoaded', (event) => {
    if (document.getElementsByClassName('blog-slider-container').length) {
      const blogSwiper = new Swiper('.blog-slider-container', {
        spaceBetween: 50,
        slidesPerView: 3,
        momentumRatio: 0.5,
        speed: 700,
        autoWidth:true,
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar',
        },
        navigation: {
          nextEl: '.next-blog',
          prevEl: '.prev-blog',
        },
        breakpoints: {
          1: {
            slidesPerView: 1,
            spaceBetweenSlides: 0,
          },
          764: {
            slidesPerView: 2,
            spaceBetweenSlides: 30,
          },
          1024: {
            slidesPerView: 2,
            spaceBetweenSlides: 50,
          },
          1700: {
            slidesPerView: 3,
            spaceBetweenSlides: 50,
          },
        },
      });
    }
    if (document.getElementsByClassName('about-swiper-container').length) {
      const aboutSwiper = new Swiper('.about-swiper-container', {
        spaceBetween: 30,
        slidesPerView: 3,
        loop: true,
        momentumRatio: 0.5,
        speed: 700,
        autoWidth:true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar',
        },
        navigation: {
          nextEl: '.next-blog',
          prevEl: '.prev-blog',
        },
        breakpoints: {
          1: {
            slidesPerView: 1,
            spaceBetweenSlides: 0,
          },
          764: {
            slidesPerView: 2,
            spaceBetweenSlides: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetweenSlides: 30,
          },
          1440: {
            slidesPerView: 4,
            spaceBetweenSlides: 30,
          },
        },
      });
      swiperHover(aboutSwiper)
    }
    if (document.getElementsByClassName('six-years-swiper-container').length) {
      const sixYearsSwiper = new Swiper('.six-years-swiper-container', {
        spaceBetween: 40,
        slidesPerView: 3,
        momentumRatio: 0.5,
        slideToClickedSlide:true,
        speed: 700,
        autoWidth:true,
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar',
        },
        navigation: {
          nextEl: '.next-blog',
          prevEl: '.prev-blog',
        },
        breakpoints: {
          1: {
            slidesPerView: 1,
            spaceBetweenSlides: 0,
          },
          764: {
            slidesPerView: 2,
            spaceBetweenSlides: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetweenSlides: 30,
          },
        },
      });
      swiperHover(sixYearsSwiper)
    }
    if (document.getElementsByClassName('related-swiper-container').length) {
      const relatedSwiper = new Swiper('.related-swiper-container', {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        momentumRatio: 0.5,
        autoHeight:true,
        speed: 700,
        autoWidth:true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
        },
        navigation: {
          nextEl: '.next-blog',
          prevEl: '.prev-blog',
        },
      });
    }

    if (document.getElementsByClassName('case-study-slider-container').length) {
      const caseStudySwiper = new Swiper('.case-study-slider-container', {
        spaceBetween: 40,
        slidesPerView: 2,
        momentumRatio: 0.5,
        speed: 700,
        centeredSlides: true,
        loop: true,
        autoWidth:true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar',
        },
        navigation: {
          nextEl: '.next-case-study',
          prevEl: '.prev-case-study',
        },
        breakpoints: {
          1: {
            slidesPerView: 1,
            spaceBetweenSlides: 0,
          },
          764: {
            slidesPerView: 2,
            spaceBetweenSlides: 30,
          },
        },
      });
    }
    if (document.getElementsByClassName('testimonial-swiper-container').length) {
      const testimonialSwiper = new Swiper('.testimonial-swiper-container', {
        spaceBetween: 40,
        slidesPerView: 1,
        momentumRatio: 0.5,
        speed: 700,
        centeredSlides: true,
        loop: true,
        autoWidth:true,
        autoHeight: true,
        autoplay: {
          delay: 15000,
        },
      });
    }
    if (document.getElementsByClassName('meet-the-team-container').length) {
      const meetTheTeamSwiper = new Swiper('.meet-the-team-container', {
        spaceBetween: 50,
        slidesPerView: 3,
        momentumRatio: 0.5,
        speed: 700,
        autoWidth:true,
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar',
        },
        navigation: {
          nextEl: '.next-member',
          prevEl: '.prev-member',
        },
        breakpoints: {
          1: {
            slidesPerView: 1,
            spaceBetweenSlides: 0,
          },
          764: {
            slidesPerView: 2,
            spaceBetweenSlides: 30,
          },
          1024: {
            slidesPerView: 2,
            spaceBetweenSlides: 50,
          },
          1100: {
            slidesPerView: 3,
            spaceBetweenSlides: 50,
          },
        },
      });
    }
});
