document.addEventListener('DOMContentLoaded', (event) => {
  if (document.querySelector('.full-header')) {
    const dropdown = document.querySelectorAll('.dropdown-nav-inner');
    const products = document.querySelector('.products-nav');
    const links = document.querySelectorAll('.header-nav ul li a');
    const fullPage = document.querySelector('body');
    const logo = document.querySelector('.header-logo');
    const toggleNav = document.querySelector('.toggle-nav');
    const mobileNav = document.querySelector('.navs-container');
    const openNav = document.querySelector('.nav-bars');
    const closeNav = document.querySelector('.fa-times');
    const closeDropdown = document.querySelector('.close-dropdown');

    links.forEach((e) => {
      e.addEventListener('mouseenter', () => {
        if (!e.dataset.nav) {
          dropdown.forEach((el) => {
            el.classList.remove('active');
          });
        }
        if (e.dataset.nav === 'services/') {
          dropdown.forEach((el) => {
            el.classList.remove('active');
          });
          products.classList.add('active');
        }
      });
    });

    document.querySelector('header').addEventListener('mouseleave', () => {
      dropdown.forEach((el) => {
        el.classList.remove('active');
      });
    });

    fullPage.addEventListener('mouseenter', () => {
      dropdown.forEach((el) => {
        el.classList.remove('active');
      });
    });

    logo.addEventListener('mouseenter', () => {
      dropdown.forEach((el) => {
        el.classList.remove('active');
      });
    });

    toggleNav.addEventListener('click', () => {
      if (mobileNav.style.display !== 'block') {
        mobileNav.style.display = 'block';
        openNav.style.display = 'none';
        closeNav.style.display = 'block';
      } else {
        mobileNav.style.display = 'none';
        openNav.style.display = 'block';
        products.classList.remove('active');
        closeNav.style.display = 'none';
      }
    });

    closeDropdown.addEventListener('click', () => {
      dropdown.forEach((el) => {
        el.classList.remove('active');
      });
    });
  }
});
