import TweenMax from "gsap/TweenMax";
document.addEventListener('DOMContentLoaded', (event) => {
const cursorWrapper = document.querySelector(".cursor-wrapper")
const cursor = document.querySelector(".cursor")
const links = Array.from(document.querySelectorAll("a:not(.case-study-container)"));
const caseStudies = Array.from(document.querySelectorAll(".case-study"));
const scope = Array.from(document.querySelectorAll(".scope"));
const openCircleButton = document.querySelector(".open-circle-button");
const closeCircleButton = document.querySelector(".close-circle-button");
const heroTopLayer = document.querySelector(".hero-top-layer");
const heroImagesFade = document.querySelector(".hero-images-fade");
const homeHeroText = document.querySelector(".home-hero-text");
const homeHeroBackText = document.querySelector(".home-hero-back-text");

if(homeHeroText && homeHeroBackText){
    function makeHeight(){
        homeHeroBackText.style.minHeight = homeHeroText.getBoundingClientRect().height + 'px';
    }
    window.requestAnimationFrame(makeHeight)
}


if(openCircleButton){
    openCircleButton.addEventListener('mouseenter',function(){
        heroTopLayer.classList.remove('open-circle');
        heroTopLayer.classList.add('close-circle');
    })

    closeCircleButton.addEventListener('mouseleave',function(){
        heroTopLayer.classList.remove('close-circle');
        heroTopLayer.classList.add('open-circle');
    })
}
});
export function swiperHover(swiper){
    if (window.innerWidth > 1060){
        swiper.allowTouchMove = false;
        swiper.el.addEventListener('mousemove',function(e){
            let rect = this.getBoundingClientRect();
            let half = rect.width/2;
            let x = e.clientX - rect.left;
            if(cursor.querySelector("i")){
                cursor.querySelector("i").remove();
            }
            const icon = document.createElement("i");
            if(x <= half){
                icon.classList.add('fal','fa-angle-left')
            }else{
                icon.classList.add('fal','fa-angle-right')
            }
            cursor.prepend(icon);
            cursor.parentElement.setAttribute('style', 'mix-blend-mode:unset')
            handleCursorEnter(iconCursorTween) 
        })
        swiper.el.addEventListener('mouseleave',function(e){
            if(cursor.querySelector("i")){
                cursor.querySelector("i").remove();
            }
            cursor.parentElement.setAttribute('style', 'mix-blend-mode:difference')
            handleCursorLeave(iconCursorTween) 
        });
        swiper.el.addEventListener('click',function(e){
            let rect = this.getBoundingClientRect();
            let half = rect.width/2;
            let x = e.clientX - rect.left;
            if(x <= half){
                swiper.slidePrev()
            }else{
                swiper.slideNext()
            }
    });
}
}

//document.addEventListener("mousemove", e => {
//    let t = e.clientX
//      , o = e.clientY;
//    TweenMax.set(cursorWrapper, {
//        x: t,
//        y: o
//    })
//}
//);

//const scopeCursorTween = TweenMax.to(cursor, .4, {
//    backgroundColor: "#fff",
//    width: 100,
//    height: 100,
//    ease: Back.easeInOut.config(1.7),
//    paused: !0
//});
//  
//const enlargeCursorTween = TweenMax.to(cursor, .3, {
//    backgroundColor: "#fff",
//    width: 40,
//    height: 40,
//    ease: Back.easeInOut.config(1.2),
//    paused: !0
//});
//
//const caseStudyCursorTween = TweenMax.to(cursor, .3, {
//    backgroundColor: "#fff",
//    width: 80,
//    height: 80,
//    ease: Back.easeInOut.config(1.2),
//    paused: !0
//});
//
//const iconCursorTween = TweenMax.to(cursor, .3, {
//    backgroundColor: "transparent",
//    borderColor:"transparent",
//    boxShadow:"transparent",
//    color:"#fff",
//    fontSize:80,
//    textShadow:"1px 6px 10px rgba(0,0,0,.5)",
//    ease: Back.easeInOut.config(1.2),
//    paused: !0
//});

//function handleCursorEnter(tween) {
//    tween.play()
//}
//function handleCursorLeave(tween) {
//    tween.reverse()
//}
//
//scope.forEach(e=>{
//    e.addEventListener("mouseenter", e=>{
//        handleCursorEnter(scopeCursorTween)
//    }
//    ),
//    e.addEventListener("mouseleave", e=>{
//        handleCursorLeave(scopeCursorTween)
//    }
//    )
//});


//links.forEach(e=>{
//    e.addEventListener("mouseenter", e=>{
//        handleCursorEnter(enlargeCursorTween)
//    }
//    ),
//    e.addEventListener("mouseleave", e=>{
//        handleCursorLeave(enlargeCursorTween)
//    }
//    )
//});
//let p;
//caseStudies.forEach(e=>{
//    e.addEventListener("mouseenter", e=>{
//        p = document.createElement("p");
//        p.innerHTML = "view";
//        cursor.prepend(p);
//        cursor.parentElement.setAttribute('style', 'mix-blend-mode:unset')
//        handleCursorEnter(caseStudyCursorTween)
//    }
//    ),
//    e.addEventListener("mouseleave", e=>{
//        cursor.querySelector("p").remove();
//        cursor.parentElement.setAttribute('style', 'mix-blend-mode:difference')
//        handleCursorLeave(caseStudyCursorTween)
//    }
//    )
//});


