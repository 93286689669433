document.addEventListener('DOMContentLoaded', (event) => {
    if(document.querySelector('.search-popup')){
        const popup = document.querySelector('.search-popup');
        const close = document.querySelector('.close-search');
        const open = document.querySelector('.open-search');
        const openMobile = document.querySelector('.open-search-mobile');

        open.addEventListener('click',function(){
            popup.classList.remove('close-search-transition');
            popup.classList.add('open-search-transition');
        });

        openMobile.addEventListener('click',function(){
            popup.classList.remove('close-search-transition');
            popup.classList.add('open-search-transition');
        });

        close.addEventListener('click',function(){
            popup.classList.remove('open-search-transition');
            popup.classList.add('close-search-transition');
        });
    }
});