import KeenSlider from 'keen-slider';

document.addEventListener( 'DOMContentLoaded', function () {
  window.jsSlider = []
  
  
  let allSliders = document.querySelectorAll('.slider-wrapper');
  
  for (let index = 0, len = allSliders.length; index < len; index++ ) {
      let slider = allSliders[index]
      let progress = slider.querySelector('.progress')

      function updateClasses(instance, slider) {
        
          let slide = instance.track.details.rel
          let arrowLeft = slider.querySelector(".arrow-left")
          let arrowRight = slider.querySelector(".arrow-right")
          if(arrowRight){
              slide === 0 ? arrowLeft.classList.add("arrow--disabled") : arrowLeft.classList.remove("arrow--disabled")
          }
          if(arrowLeft){
              slide === instance.track.details.size - 1 ? arrowRight.classList.add("arrow--disabled") : arrowRight.classList.remove("arrow--disabled")
          }

          if(slider.classList.contains('pagination')){
            slider.parentNode.querySelectorAll(".dot").forEach(function (dot, idx) {
                idx === slide
                ? dot.classList.add("dot--active")
                : dot.classList.remove("dot--active")
            })
          }
      }
      
      let sliderInterval = 0;
          function autoplay(mainSlider, run) {
          clearInterval(sliderInterval);
          sliderInterval = setInterval(() => {
              if (run && mainSlider) {
                  mainSlider.next();
              }
          }, 5000);
      }

      let extraData = {
        created: function (instance) {
          if(slider.querySelector(".arrow-left")){
            slider.querySelector(".arrow-left")
            .addEventListener("click", function () {
                  instance.prev()
                  if (progress) {
                      progress.classList.remove('active');
                      setTimeout(() => {
                          progress.classList.add('active');
                      }, 50);
                  }
            })
          
        }
        if(slider.querySelector(".arrow-right")){
          
            slider.querySelector(".arrow-right")
            .addEventListener("click", function () {
                  instance.next()
                  if (progress) {
                      progress.classList.remove('active');
                      setTimeout(() => {
                          progress.classList.add('active');
                      }, 50);
                  }
            })
        }
          
          instance.container.addEventListener('mousemove',(e) => {
            let cursor = document.querySelector('.cursor');
            let x = e.clientX; 
            let y = e.clientY;
            cursor.style.left = `${x}px`;
            cursor.style.top = `${y}px`;
          },{capture:true})


          let rect = slider.getBoundingClientRect();
          let fourthrect = (rect.width / 4);
          let lastfourth = (rect.width - fourthrect);

          window.addEventListener('resize',function(){
            rect = slider.getBoundingClientRect();
            fourthrect = (rect.width / 4);
          });

          slider.addEventListener('mousemove',function(d){
            let x = d.clientX - rect.left;
            if(x < fourthrect){
                slider.classList.remove('arrow-right');
                slider.classList.add('arrow-left');
                slider.classList.remove('drag-text');
              }else if(x > lastfourth){
                slider.classList.remove('arrow-left');
                slider.classList.add('arrow-right');
                slider.classList.remove('drag-text');
              }else if(x > fourthrect && x < lastfourth){
                slider.classList.remove('arrow-left');
                slider.classList.remove('arrow-right');
                slider.classList.add('drag-text');
              }
        });

         slider.addEventListener("click", function () {
          console.log(slider)
             if(slider.classList.contains('arrow-left')){
               instance.prev()
             }
             else if(slider.classList.contains('arrow-right')){
               instance.next()
             }
         })
        

        //  if(slider.classList.contains('progress-bar')){
        //    let progressBarContainer = document.createElement("div");
        //    progressBarContainer.classList.add('progress-bar-container');
        //    let progressBar = document.createElement("div");
        //    progressBar.classList.add('progress-bar-inner');
        //    progressBarContainer.append(progressBar);
        //    slider.append(progressBarContainer);
        //    let progressSections;
        //    progressBar.style.width = progressSections + '%';
        //    progressSections = 100 / (instance.track.details.slides.length+1);
        //    progressBar.style.width = (instance.track.details.rel+1) * progressSections + '%'
        //  }
    
        //  if(slider.classList.contains('pagination')){
        //      var dots_wrapper = slider.parentNode.querySelector(".dots");
        //      let slides = slider.querySelectorAll(".keen-slider__slide")
        //      slides.forEach(function (t, idx) {
        //        let dot = document.createElement("button")
        //        dot.classList.add("dot","link")
        //        dots_wrapper.appendChild(dot)
        //        dot.addEventListener("click", function () {
        //            instance.moveToIdx(idx)
        //        })
        //      })
        //    }
        //    updateClasses(instance, slider)
         },
        
        slideChanged(instance) {
          updateClasses(instance, slider);
          if(slider.dataset.slider){
            let dataSlider = JSON.parse(slider.dataset.slider)
            dataSlider = dataSlider;
            if(slider.classList.contains('progress-bar')){
              let progressSections;
              let progressBar = slider.querySelector('.progress-bar-inner');
              progressBar.style.width = progressSections + '%';
              progressSections = 100 / (instance.track.details.slides.length+1);
              progressBar.style.width = (instance.track.details.rel+1) * progressSections + '%'
            }
            if(dataSlider.navFor){
              let slide = instance.track.details.rel;
              let size = instance.track.details.size;
              if(window.jsSlider[dataSlider.navFor]){
                window.jsSlider[dataSlider.navFor].moveToSlide(slide);
              }
            }
          }
          
          
        }
      }
      
      if(slider.dataset.slider){
        let dataSlider = JSON.parse(slider.dataset.slider)
        
        dataSlider = dataSlider;
        dataSlider = {...dataSlider, ...extraData }
        

        if(dataSlider.progress && progress){
          progress.classList.add('active')
        }
        
        let autoPlayData = {
          dragStart: (e) => {
              autoplay(mainSlider, false)
          },
          dragEnd: (e) => {
              autoplay(mainSlider, true)
          }
        }
        
        if(dataSlider.autoplay){
          dataSlider = {...dataSlider, ...autoPlayData }
        }
        
        let selectSlider = slider.querySelector('.keen-slider');
        let mainSlider = new KeenSlider(selectSlider, dataSlider)

        if(dataSlider.autoplay){
            autoplay(mainSlider, true)
        }
        
      
        if(dataSlider.id){
            console.log("id: ", dataSlider.id);
            window.jsSlider[dataSlider.id] = mainSlider
        }
        
      }else{
        console.log("Please add the data-slider values")
      }
    
  }

  
});
